import {combineReducers} from 'redux';
import appReducer from './appReducer';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import customerReducer from './customerReducer';
import cartReducer from './cartReducer';

const rootReducer = combineReducers({
    app : appReducer,
    auth : authReducer,
    profile : profileReducer,
    customer : customerReducer,
    cart : cartReducer
});


export default rootReducer;