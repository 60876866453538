import React from 'react';
import { connect } from 'react-redux';
import { fonts, colors } from '../../assets/css/theme';

class Contact extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);

    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="Contact">
                
                <p className="text">If you have any issues please contact our team on:</p>
                <a className="link-contact" href="https://arcadiamedical.co.uk/contact-us/" target="_blank" rel="noreferrer"><h3 className="title contact-button">Contact Us</h3></a>
                <a className="link" href="https://arcadiamedical.co.uk/contact-us/" target="_blank" rel="noreferrer">info@arcadiamedical.co.uk</a>
                <a href="tel:01234567890" className="tel">01792 940270</a><br/>
                <style jsx='true'>{`
                    .contact-button {
                        background: #143C6B;
                        color:#fff;
                        font-size:10px;
                        text-transform: uppercase;
                        display: block;
                        padding:7px 20px;
                        border:none;
                        text-decoration: none;
                        border-radius: 15px;
                        cursor: pointer;
                        text-align:center;
                    }
                    .title {
                       ${fonts.bold} 
                       color : #fff;
                       font-size:19px;
                       margin:15px 0px;
                    }
                    .text {
                        ${fonts.reg}
                        color : ${colors.midBlue};
                        margin:10px 0px;
                    }
                    .tel {
                        ${fonts.bold}
                        color:${colors.midBlue};
                        font-size:14px;
                        display: block;
                        margin:10px 0px;
                        text-decoration: none;
                    }
                    .link {
                        ${fonts.bold}
                        color:${colors.midBlue};
                        text-decoration: none;
                    }
                    .link:link {
                        text-decoration: none;
                    }
                    .tel:hover {
                        text-decoration: none;
                    }
                    .link-contact {
                        ${fonts.bold}
                        color:#fff;
                        text-decoration: none;
                    }
                    .link:link {
                        text-decoration: none;
                    }
                    .link-contact:hover {
                        text-decoration: underline;
                    }
                    @media screen and (max-width:767px) {
                        .Contact {
                            display: none;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

Contact.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(Contact);
