import React from 'react';
import Button from './Button';
import { fonts, colors } from '../../assets/css/theme';

class Popover extends React.Component {   

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        if(!this.props.isOpen) return null;

     
        return (
            <div className="Popover" onClick={this.props.onClose}>
                <div className="Popover-content-wrap">
                    <div className="Popover-content">
                        {this.props.children}
                        <div className="Popover-foot">
                            <Button 
                                onClick={this.props.onClose}
                                style={{width :130}}
                                working={false}
                            >Done</Button>
                        </div>
                    </div>
                </div>
                
                
                <style jsx='true'>{`

                    @keyframes PopoverIn {
                        0% {
                            opacity: 0;
                            
                        }
                      
                        100% {
                            opacity: 1;
                           
                        }
                    }

                    .Popover {
                        position: fixed;
                        top:0px;
                        bottom:0px;
                        left:0px;
                        right:0px;
                        background: rgba(0,0,0,0.8);
                        display: flex;
                        flex-direction: column;
                        padding:10px!important;
                        animation: PopoverIn 0.3s;
                        animation-fill-mode: forwards;
                        animation-timing-function: ease-in-out;
                        min-height:1px;
                        align-items:center;
                        z-index: 1;
                       
                    }  
                    .Popover-content-wrap {
                        margin: auto!important;
                        overflow-y: auto!important;
                        overflow-x:hidden;
                        
                    }
                    .Popover-content {
                       
                        border-radius: 5px;
                        padding:30px!important;
                        background: #fff;
                        position: relative;
                        
                    }  
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        .Popover-content-wrap {
                            min-width:80vh;
                        }
                    }
                    @media print {
                       .Popover {
                           background: #fff;
                       }
                       .Popover.value.large {
                           font-size:28px;
                       }
                       .Popover-content-wrap {
                           width:100%;
                       }
                       .Popover {
                           display: block;
                       }
                       .Popover-foot {
                           display: none;
                       }
                   }
                   
                   

                    
                    .Popover-foot {
                        margin-top:30px;
                        text-align: right;
                    }
                    

                `}</style>

                <style jsx='true'>{`
                    .Popover .item {
                        display: flex;
                        ${fonts.reg}
                        font-size:14px;
                        padding:10px 0px;
                        align-items: center;
                        border-bottom:1px solid ${colors.mid};
                        background: #fff;
                    }
                    .Popover .code {
                        width: 100px;
                    } 
                    
                    .Popover .gtin {
                        width:130px;
                        text-align: center;
                    }
                    .Popover .title {
                        flex:1;
                        margin: 0px;
                    }
                    .Popover .type {
                        ${fonts.bold}
                        font-size:12px;
                        color:${colors.mid};
                    }
                    .Popover .name {
                        ${fonts.bold}
                        font-size:14px;
                        
                    }
                    .Popover .price {
                        width:90px;
                        ${fonts.bold}
                        font-size:14px;
                        text-align: center;
                    }   
                    .Popover .orderTag {
                        width:120px;
                        ${fonts.bold}
                        font-size:14px;
                        text-align: center;
                    } 
                    .Popover .lineTotal {
                        width:90px;
                        ${fonts.bold}
                        font-size:14px;
                        text-align: right;
                    }

                    @media screen and (max-width:767px){
                        .Popover .item {
                            flex-wrap: wrap;
                        }
                        .Popover .code, .Popover .gtin, .Popover .name, .Popover .price {
                            width: 100%;
                            text-align: left;
                        }
                        .Popover .price {
                            margin-bottom:10px;
                        }
                        .Popover .title {
                            width: 100%;
                            padding-left:0px;
                            display: block;
                        }
                        .Popover .lineTotal {
                            flex:1;
                            font-size: 22px;
                        }
                        .Popover .orderTag {
                            text-align: left;
                        }
                        .Popover .orderTag:before {
                            content : "Qty: "
                        }
                    }
                    
                    @media print {
                        .Popover .item,
                        .Popover .price,
                        .Popover .orderTag,
                        .Popover .lineTotal
                        {
                            font-size:12px;
                        }
                        .Popover .code {
                            padding-right:10px;
                            width: 120px;
                            text-overflow: ellipsis;
                        }
                    }

                    .Order .label,
                    .Order .value {
                        width: auto;
                    }

                    .Order .name {
                        color: ${colors.dark};
                    }

                    .Order .label {
                        color: ${colors.dark};
                    }

                    .Popover .footer .label {
                        color: ${colors.dark};
                        width: 170px;
                        margin: 0px;
                    }

                    .Popover .footer .value {
                        color: ${colors.dark};
                        width: 170px;
                    }

                    .Popover .total {
                        display: flex;
                        margin-top: 20px;
                    }

                    .Popover .Button {
                        padding: 14px 22px;
                        height: auto;
                    }
                `}</style>
            </div>
        )
    }
}

Popover.propTypes = {
    
}

export default Popover;