import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import axios from 'axios';
import moment from 'moment'
import { handleCustomerChangeOnCart } from './cartAction';


export const switchCustomer = (customer)=>{
    return (dispatch, getState)=>{
        dispatch({
            type: 'CUSTOMER_PROFILE_UPDATE',
            payload :customer
        });

        dispatch(updateCustomerBPSelected())
        dispatch(loadCustomer(customer.SAPBP, customer.PriceList))
        dispatch(handleCustomerChangeOnCart(customer.SAPBP, customer.PriceList));
    }
}
export const loadCustomer = (customerId, pricelistId)=>{
    
    return (dispatch, getState)=>{
        getState().customer.listeners.map((f) => f())
        
        if(!customerId){
            dispatch({ type: 'CUSTOMER_PROFILE_UPDATE', payload : {}});
            dispatch({ type: 'CUSTOMER_INVOICES_UPDATE', payload : []});
            dispatch({ type: 'CUSTOMER_DELIVERIES_UPDATE', payload : []});
            dispatch({ type: 'CUSTOMER_CREDITS_UPDATE', payload : []});
            dispatch({ type: 'CUSTOMER_ORDERS_UPDATE', payload : []});
            dispatch({ type: 'CUSTOMER_ORDER_META_UPDATE', payload : {}});
            dispatch({
                type: 'ENABLED_FOR_CUSTOMER',
                payload : false
            });
            return;
        }

        

        let listeners = [];

        const collectionSnapshot = (snapShot, type)=>{
            
        
                
            let records = [];
                snapShot.forEach((record)=>{
                    records.push(record.data())
                });
                dispatch({
                    type: type,
                    payload : records
                });

            if(type==='CUSTOMER_ORDERS_UPDATE'){
                    let backOrders = [];
                    let ordersByKey = {};
                    records
                        .forEach((order, idx)=>{
                            ordersByKey[order.WebOrderNum]=order;
                            if(order.Status==='Open'){
                                order.Lines.forEach((line, idx)=>{ 
                                    if(line != undefined && line.LineStatuses && line.LineStatuses.length){
                                        line.LineStatuses.forEach((lineStatus, idx)=>{
                                            if(lineStatus.Status==='Back-Ordered'){
                                                backOrders.push({
                                                    ItemCode : line.ItemCode,
                                                    OrderDate : order.DocDate,
                                                    OrderId : order.WebOrderNum,
                                                    OrderPlacedByName : order.OrderPlacedByName,
                                                    CustomerRef : order.CustomerRef,
                                                    ...lineStatus
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                            
                    });

                   
                    dispatch({
                        type: 'CUSTOMER_ORDERS_ORDER_META_UPDATE',
                        payload : {
                            backOrders : backOrders,
                            ordersByKey : ordersByKey
                        }
                    });
            }
        }

        listeners[0] = firebase.firestore().collection('Invoices')
        .where('SAPBP',"==", customerId)
        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_INVOICES_UPDATE')})
        

        listeners[1] = firebase.firestore().collection('Deliveries')
        .where('SAPBP',"==", customerId)
        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_DELIVERIES_UPDATE')})
        

        listeners[2] = firebase.firestore().collection('Credits')
        .where('SAPBP',"==", customerId)
        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_CREDITS_UPDATE')})
        

        listeners[3] = firebase.firestore().collection('Orders')
        .where('SAPBP',"==", customerId)
        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_ORDERS_UPDATE')})
        

        listeners[4] = firebase.firestore().collection('accessRequests')
        .where('SAPBP',"==", customerId)
        .where('createdAt', ">", moment().subtract(1, 'week').toDate())
        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_ACCESS_REQUESTS_UPDATE')})
        

        if(pricelistId){
            listeners[5]=firebase.database().ref('pricesLists/pl' +pricelistId).on('value',(snapShot)=>{
                 let plItems = {};
                 if(snapShot){
                    snapShot.forEach(function(snap) {
                        plItems[snap.key] =  snap.val()
                    });
                 }
                 
                 dispatch({
                     type: 'CUSTOMER_PRICE_LIST_UPDATE',
                     payload : plItems
                 });
             })
         } else {
             dispatch({
                 type: 'CUSTOMER_PRICE_LIST_UPDATE',
                 payload : {}
             });
         }

        dispatch({
            type: 'CUSTOMER_LISTENERS_UPDATED',
            payload : listeners
        });
        
        dispatch({
            type: 'ENABLED_FOR_CUSTOMER',
            payload : true
        });
    }
}

export const updateCustomerBPSelected = () => {

    return (dispatch, getState)=> {
        
        // Refactor extract this logic to separate class as it`s used in different parts
        const collectionSnapshot = (snapShot, type)=>{
                            
            let records = [];
                snapShot.forEach((record)=>{
                    records.push(record.data())
                });
                dispatch({
                    type: type,
                    payload : records
                });

            if(type==='CUSTOMER_ORDERS_UPDATE'){
                    let backOrders = [];
                    let ordersByKey = {};
                    records.forEach((order, idx)=>{
                        if(order.Status==="Open"){
                            ordersByKey[order.WebOrderNum]=order;
                            order.Lines.forEach((line, idx)=>{
                                
                                if(line.LineStatuses && line.LineStatuses.length){
                                    line.LineStatuses.forEach((lineStatus, idx)=>{
                                        if(lineStatus.Status==='Back-Ordered'){
                                            backOrders.push({
                                                ItemCode : line.ItemCode,
                                                OrderDate : order.DocDate,
                                                OrderId : order.WebOrderNum,
                                                OrderPlacedByName : order.OrderPlacedByName,
                                                CustomerRef : order.CustomerRef,
                                                ...lineStatus
                                            })
                                        }
                                    })
                                }
                            })
                        }
                        
                    });

                   
                    dispatch({
                        type: 'CUSTOMER_ORDERS_ORDER_META_UPDATE',
                        payload : {
                            backOrders : backOrders,
                            ordersByKey : ordersByKey
                        }
                    });
            }
        }

        //listen to the profile
        firebase.auth().onAuthStateChanged(async (user)=> {
            if(user){
                
                await firebase.firestore()
                .collection('permissions')
                .doc(user.uid)
                .onSnapshot(async (snap)=>{
                    
                    let userGroup = snap.data();
                    if(userGroup){

                        // Check if a customer was selected in the switchCustomer 
                        // otherwise take the first customerId which the user has permissions
                        let customerBP = getState().customer?.profile?.SAPBP !== undefined ? getState().customer?.profile?.SAPBP : userGroup.customerId[0];
                        let getBPFromSelectedCustomer = await firebase.firestore().collection('BPs').doc(customerBP).get();

                        let customerBPProductGroups = getBPFromSelectedCustomer.data().ProductGroups;        
                        let productGroupsAllowedToCustomers = []; 
                        
                        for(let groupKey in customerBPProductGroups) {

                            if(customerBPProductGroups[groupKey] === "YES") { 
                                // In the Items collection Original Products Product Group is written with space
                                // So we need to check all Groups names from BP collection and pass the correct form to be used in Firestore where clause below
                                if(groupKey === 'OriginalProduct'){
                                    groupKey = 'Original Product';
                                }

                                productGroupsAllowedToCustomers.push(groupKey);
                            }
                        }

                        firebase.firestore()
                        .collection('Items')
                        .where('Group', 'in', productGroupsAllowedToCustomers)
                        .onSnapshot((snapShot)=>{ collectionSnapshot(snapShot, 'ITEMS_UPDATE') })
                        

                    }
                 });
            

            }
        });


    }
}


export const loadFirebase = ()=> {

    return (dispatch, getState)=> {

        

        return new Promise((resolve, reject)=>{

            dispatch({type: 'FIREBASE_INITIALISING'})
            

            const collectionSnapshot = (snapShot, type)=>{
                

                
                let records = [];
                    snapShot.forEach((record)=>{
                        records.push(record.data())
                    });
                    dispatch({
                        type: type,
                        payload : records
                    });

                if(type==='CUSTOMER_ORDERS_UPDATE'){
                        let backOrders = [];
                        let ordersByKey = {};
                        records.forEach((order, idx)=>{
                            if(order.Status==="Open"){
                                ordersByKey[order.WebOrderNum]=order;
                                order.Lines.forEach((line, idx)=>{
                                    
                                    if(line.LineStatuses && line.LineStatuses.length){
                                        line.LineStatuses.forEach((lineStatus, idx)=>{
                                            if(lineStatus.Status==='Back-Ordered'){
                                                backOrders.push({
                                                    ItemCode : line.ItemCode,
                                                    OrderDate : order.DocDate,
                                                    OrderId : order.WebOrderNum,
                                                    OrderPlacedByName : order.OrderPlacedByName,
                                                    CustomerRef : order.CustomerRef,
                                                    ...lineStatus
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                            
                        });

                       
                        dispatch({
                            type: 'CUSTOMER_ORDERS_ORDER_META_UPDATE',
                            payload : {
                                backOrders : backOrders,
                                ordersByKey : ordersByKey
                            }
                        });
                }
            }

            const docSnapshop = (snapShot, type)=>{
                let record = snapShot.data();
                dispatch({
                    type: type,
                    payload : record
                });
            }

            
            const initFirebase = (config)=>{


                if (!firebase.apps.length) {
                    firebase.initializeApp(config);
                    firebase.firestore().settings({ experimentalForceLongPolling: true });
                    firebase.app().functions('europe-west2');
                    resolve(true)
                } else {
                    resolve(true)
                }



                firebase.database().ref('.info/connected').on('value', (snap)=> {
                    dispatch({type: 'FIREBASE_CONNECTED', payload: snap.val()})
                })


                firebase.auth().onAuthStateChanged((user)=> {
                    dispatch({type: 'FIREBASE_AUTHSTATE_CHANGE', payload: user});
                })
                
                
                

       

                //listen to the profile
                firebase.auth().onAuthStateChanged(async (user)=> {
                    if(user){
                        //load their profile
                        let profileListener = await firebase.firestore().collection('users').doc(user.uid)
                        .onSnapshot(
                            async (snapShot)=>{
                                
                                
                                let profile = snapShot.data();
                                dispatch({
                                    type: 'USER_PROFILE_UPDATE',
                                    payload : profile
                                });

                                try {
                                    await user.getIdToken(true);
                                    let token = await firebase.auth().currentUser.getIdTokenResult();

                                    if(token.claims.customerId){

                                        dispatch({
                                            type: 'ENABLED_FOR_CUSTOMER',
                                            payload : true
                                        });
                                        dispatch({
                                            type: 'GROUP_CUSTOMERS_UPDATE',
                                            payload : []
                                        });

                                        firebase.firestore().collection('BPs').doc(token.claims.customerId)
                                        .onSnapshot((snapShot)=>{docSnapshop(snapShot, 'CUSTOMER_PROFILE_UPDATE')})

                                        dispatch(loadCustomer(token.claims.customerId, token.claims.pricelistId))


                                    } else if (token.claims.isGroup){

                                        //load permissions
                                        /*let userGroupCall =*/ await firebase.firestore()
                                        .collection('permissions')
                                        .doc(user.uid)
                                        .onSnapshot(async (snap)=>{
                                           
                                            let userGroup = snap.data();
                                            if(userGroup){
                                                let resp = await Promise.all(
                                                    userGroup.customerId.map((g)=>{
                                                        return firebase.firestore().collection('BPs').doc(g).get();
                                                    })
                                                )
        
                                                let groupCustomers = resp.map((doc)=>doc.data());
        
                                                
                                                dispatch({
                                                    type: 'GROUP_CUSTOMERS_UPDATE',
                                                    payload : groupCustomers
                                                });
        
                                                dispatch({
                                                    type: 'CUSTOMER_PROFILE_UPDATE',
                                                    payload : groupCustomers[0]
                                                });
        
                                                dispatch(loadCustomer(groupCustomers[0].SAPBP, groupCustomers[0].PriceList))
                                            }
                                            

                                            
                                        });
                                        
                                    
                                    } else {
                                        dispatch(loadCustomer())
                                    }



                                    if(token.claims.pricelistId){
                                       firebase.database().ref('pricesLists/' +token.claims.pricelistId).on('value',(snapShot)=>{
                                            let plItems = {};
                                            snapShot.forEach(function(snap) {
                                                plItems[snap.key] =  snap.val()
                                            });
                                            dispatch({
                                                type: 'CUSTOMER_PRICE_LIST_UPDATE',
                                                payload : plItems
                                            });
                                        })
                                    } else {
                                        dispatch({
                                            type: 'CUSTOMER_PRICE_LIST_UPDATE',
                                            payload : {}
                                        });
                                    }

                                  

                                }
                                catch(e){
                                    console.error(e);
                                    dispatch({
                                        type: 'CUSTOMER_PROFILE_UPDATE',
                                        payload : null
                                    });
                                    dispatch({
                                        type: 'CUSTOMER_PRICE_LIST_UPDATE',
                                        payload : {}
                                    });
                                }
                                
                            }
                        );
                        dispatch({
                            type: 'USER_PROFILE_LISTENER_UPDATE',
                            payload : profileListener
                        });  

                        
                        await firebase.firestore()
                            .collection('permissions')
                            .doc(user.uid)
                            .onSnapshot(async (snap)=>{
                                
                                let userGroup = snap.data();
                                if(userGroup){

                                    // Check if a customer was selected in the switchCustomer 
                                    // otherwise take the first customerId which the user has permissions
                                    let customerBP = getState().customer?.profile?.SAPBP !== undefined ? getState().customer?.profile?.SAPBP : userGroup.customerId[0];
                                    let getBPFromSelectedCustomer = await firebase.firestore().collection('BPs').doc(customerBP).get();
                                    let customerBPProductGroups = getBPFromSelectedCustomer.data().ProductGroups;                                   
                                    let productGroupsAllowedToCustomers = []; 
                                    
                                    for(let groupKey in customerBPProductGroups) {

                                        if(customerBPProductGroups[groupKey] === "YES") { 
                                            // In the Items collection Original Products Product Group is written with space
                                            // So we need to check all Groups names from BP collection and pass the correct form to be used in Firestore where clause below
                                            if(groupKey === 'OriginalProduct'){
                                                groupKey = 'Original Product';
                                            }

                                            productGroupsAllowedToCustomers.push(groupKey);
                                        }
                                    }

                                    firebase.firestore()
                                    .collection('Items')
                                    .where('Group', 'in', productGroupsAllowedToCustomers)
                                    .onSnapshot((snapShot)=>{ collectionSnapshot(snapShot, 'ITEMS_UPDATE') })
                                    

                                }
                        });
                            
                        
                        await firebase.firestore().collection('permissions').doc(user.uid)
                        .onSnapshot((snapShot)=>{docSnapshop(snapShot, 'USER_PERMISSIONS_UPDATE')})

                        await firebase.firestore().collection('userCarts').doc(user.uid)
                        .onSnapshot((snapShot)=>{docSnapshop(snapShot, 'USER_CART_UPDATE')})


                    } else {
                        dispatch({
                            type: 'USER_PROFILE_UPDATE',
                            payload : null
                        });
                        dispatch({
                            type: 'USER_PROFILE_LISTENER_UPDATE',
                            payload : null
                        });  
                    }



                })

            
            }


            axios.get('./__/firebase/init.json')
                .then(
                    (resp)=> {
                if(resp.data.databaseURL){
                //it's a valid firebase object
                initFirebase(resp.data);
            } else {
                initFirebase({
                    apiKey: "AIzaSyBPfsM9vXuIz4eDjQsIi-VeUVIpiz8sMyY",
                    authDomain: "arcadia-dev-193e0.firebaseapp.com",
                    databaseURL: "https://arcadia-dev-193e0.firebaseio.com",
                    projectId: "arcadia-dev-193e0",
                    storageBucket: "arcadia-dev-193e0.appspot.com",
                    messagingSenderId: "1012169350742"
                });
                console.log('response invalid');
            }
        }
        )
        .catch((e)=> {
                //default for local testing
                initFirebase({
                    apiKey: "AIzaSyBPfsM9vXuIz4eDjQsIi-VeUVIpiz8sMyY",
                    authDomain: "arcadia-dev-193e0.firebaseapp.com",
                    databaseURL: "https://arcadia-dev-193e0.firebaseio.com",
                    projectId: "arcadia-dev-193e0",
                    storageBucket: "arcadia-dev-193e0.appspot.com",
                    messagingSenderId: "1012169350742"
                });

        })

        })
        

        //load auth from /__/firebase/init.json - JSON representation of the configuration
        //https://firebase.googleblog.com/2017/04/easier-configuration-for-firebase-on-web.html
        //Maybe load it and if it fails (which it will locally, fallback to the default test one).



    }
}
export const sidebarActive = (open)=>{
    return (dispatch)=>{
        dispatch({
            type: 'SET_SIDEBAR_STATE',
            payload : open
        });
    }
}
