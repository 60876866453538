import React from 'react';
import PaginationControls from '../common/PaginationControls';
import TableRow from '../common/TableRow';
import TableCell from './../common/TableCell';
import TableHeader from '../common/TableHeader';
import ViewButton from '../common/ViewButton';
import ToggleOption from '../common/ToggleOption';
import { effects } from '../../assets/css/theme';
import moment from 'moment';
import Status from '../common/Status';

class OrdersList extends React.Component {   

    constructor(props){
        super(props);
        this.renderRows = this.renderRows.bind(this);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    renderRows(){

        const formatDate = (str)=>{
            if(!str) return '';
            try {
                return moment(str).format('DD-MM-YYYY');
            }
            catch(e){
                return '';
            }
        }

        
        return this.props.records
            .filter((i, idx)=>{
                //pagination filter
                if(idx > (this.props.currentPage * this.props.paginationCount)+(this.props.paginationCount-1)) return false;
                if(idx < this.props.currentPage * this.props.paginationCount) return false;
                return true;
            })
            .map((r, idx)=>{
                // When filtering the orders by searching the items are
                // returned with a wrapping object and the actual data is
                // inside the "item" key. This is a quick fix and should
                // probably be fixed at the root but that may break other
                // parts of the site.
                r = typeof r.CustomerRef === 'undefined' ? r.item : r;

                return (r !== undefined) ? (
                    <TableRow key={idx}>
                        <TableCell className="date">{formatDate(r?.DocDate)}</TableCell>
                        <TableCell className="po">{r?.CustomerRef}</TableCell>
                        <TableCell className="status">{<Status mode="orders">{r?.Status}</Status>}</TableCell>
                        <TableCell className="total">£{r?.Total?.toFixed(2)}</TableCell>
                        <TableCell className="control"><ViewButton onClick={()=>{this.props.onOrderSelect(r)}} /></TableCell>
                    </TableRow>
                ) : <></>
            })
    }
    
    render(){
        return (
            <div className="Orders-wrap">
                    <div className="toggleWrap">
                        <ToggleOption 
                            label="Show only open orders"
                            value={this.props.onlyOpenValue}
                            onClick={this.props.onlyOpenAction}
                            reverse={true}
                        />
                    </div>
                    
                    
                    <div className="Orders-records">
                        <TableRow> 
                            <TableHeader className="date">Order Date</TableHeader>
                            <TableHeader className="po">PO Number</TableHeader>
                            <TableHeader className="status">Status</TableHeader>
                            <TableHeader className="total">Total</TableHeader>
                            <TableHeader className="control"> </TableHeader>
                        </TableRow>
                        {this.renderRows()}
                    </div>


                    <PaginationControls
                        style={{marginTop:20}}
                        totalPages={this.props.totalPages}
                        currentPage={this.props.currentPage}
                        onChange={this.props.onPaginationChange} 
                    />



                <style jsx='true'>{`
                    .Orders-wrap {
                        padding:20px;
                        background: #fff;
                        ${effects.shadow1}
                        margin-top:0px;
                        min-height: 70vh;
                    }
                    .toggleWrap {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom:20px;
                    }

                    .Orders-records .date{
                        text-align: left;
                        width:150px;
                    }
                    .Orders-records .po{
                        flex:1;
                        text-align: left;
                        
                    }

                    .Orders-records .name{
                        width:180px;
                       
                    }

                    .Orders-records .price{
                        width:100px;
                    }

                    
                    .Orders-records .status{
                        width:100px;
                        white-space: normal;
                        font-size:0.9em;
                    }
                    .Orders-records .total{
                        width:100px;
                        text-align: right;
                    }

                    .Orders-records .control{
                        width:50px;
                        text-align: right;
                    }

                    

                    @media screen and (max-width:767px){
                       
                        .Orders-records .date{
                            text-align: left;
                            width:100%;
                        }
                        .Orders-records .po{
                            flex:1;
                            text-align: left;
                            
                        }

                        .Orders-records .name{
                            width:100%;
                            text-align: left;
                            margin-bottom:10px;
                        
                        }

                        .Orders-records .price{
                            width:100%;
                            text-align: left;
                            
                        }

                        
                        .Orders-records .status{
                            text-align: left;
                            font-size:0.9em;
                        }
                        .Orders-records .total{
                            width:auto;
                            text-align: right;
                            font-size:18px;
                            flex:1;
                        }

                        .Orders-records .control{
                            width:50px;
                            text-align: right;
                        }
                    }

                `}</style>
                    

                </div>
        )
    }
}

OrdersList.propTypes = {
    
}

export default OrdersList;