import React from 'react';
import { connect } from 'react-redux';
import logoSrc from './../../assets/img/logo-blue.svg'

import { colors, fonts, effects } from '../../assets/css/theme';
import { logoutUser } from '../../actions/authAction';
import { sidebarActive } from '../../actions/appAction';
import CustomerSwitcher from './CustomerSwitcher';



class HeaderBar extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);

    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){

        const renderName = ()=>{
            let parts = [];
            if(this.props.authedUser && this.props.authedUser.email){
                parts.push(this.props.authedUser.email);
            }

            if(this.props.profile && this.props.profile.firstName){
                parts.push(this.props.profile.firstName+" "+this.props.profile.lastName)
            }
            return parts.join(" - ");
        }

        const renderHospital = ()=>{
            if(this.props.permissions && Array.isArray(this.props.permissions.customerId)){
               return <CustomerSwitcher />
            }

            if(this.props.profile && this.props.profile.hospitalName){
                return (
                    <div className="userHospital">
                        {this.props.profile.hospitalName}
                        <style jsx='true'>{`
                            .userHospital {
                                color:${colors.midBlue};
                                ${fonts.bold};
                                text-align: right;
                            }    
                        `}</style>    
                    </div> 
                )
            }
            return '';
        }

        const renderStatus = ()=>{
            if(this.props.enabledForCustomer){
                return 'ACTIVE';
            }

            
            if(this.props.profile && this.props.profile.firstName){
                return 'PENDING'
            }

            return 'NEW'

            
        }
        return (
            <div className="HeaderBar">
                <div className="top">
                    <div className="logo">
                        <img className="logoImg" src={logoSrc} alt=""/>
                    </div>
                    <div className="userArea">
                        <div className="userArea-inner">
                            <div className="userName">{renderName()}</div>
                            {renderHospital()}


                            <div className="button">
                                <svg width="8" height="6" viewBox="0 0 8.643 6.461">
                                    <path className="downArrow"  d="M1723.785,38l4.555,6.461L1732.428,38Z" transform="translate(-1723.785 -38)" />
                                </svg>
                                
                                <div className="signOut">
                                    <div className="logoutButton" onClick={this.props.logoutUser}>
                                        Log out
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                    <div className="small-menu">
                        <button
                            onClick={()=>{
                                console.log('ddd')
                                this.props.sidebarActive(true);
                            }}
                        >
                            <svg width="45" height="45" viewBox="0 0 45 45">
                                <g id="Close">
                                    <path d="M21,21H11.91a1.51,1.51,0,0,0,0,3H33.09a1.51,1.51,0,0,0,0-3H21Z" fillRule="evenodd"/>
                                </g>
                                <g id="Close-2" data-name="Close">
                                    <path d="M21,29.23H11.91a1.52,1.52,0,0,0,0,3H33.09a1.52,1.52,0,0,0,0-3H21Z" fillRule="evenodd"/>
                                </g>
                                <g id="Close-3" data-name="Close">
                                    <path d="M21,12.74H11.91a1.52,1.52,0,0,0,0,3H33.09a1.52,1.52,0,0,0,0-3H21Z" fillRule="evenodd"/>
                                </g>
                            </svg>

                        </button>

                    </div>
                </div>
                <div className="blue-bar">
                    ACCOUNT STATUS: {renderStatus( )}
                    
                </div>
                
                <style jsx='true'>{`
                    .HeaderBar .small-menu {
                        display: none;
                    }
                    .HeaderBar .top {
                        display: flex;
                        justify-content: space-between;
                        padding:5px 20px;
                    }
                    .HeaderBar .logoImg {
                        width: 214px;
                        height:72px;
                    }
                    .HeaderBar .blue-bar {
                        background: ${colors.loBlue};
                        color:#fff;
                        ${fonts.bold};
                        text-align: right;
                        padding:5px 20px;
                        font-size:12px;
                    }
                    .HeaderBar .userArea {
                        display: flex;
                        align-items: center;
                    }
                    .HeaderBar .userName {
                        color:${colors.loBlue};
                        ${fonts.bold};
                        text-align: right;
                    }  
                    .HeaderBar .userArea-inner {
                        position: relative;
                        padding-right:30px;
                    }
                    .HeaderBar .downArrow {
                        fill : ${colors.mid};
                    }
                    .HeaderBar .button {
                        position: absolute;
                        right:0px;
                        top:3px;
                        bottom:0px;
                        width: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        border:1px solid ${colors.mid};
                    }
                    .HeaderBar .button:hover {
                        border-radius: 5px 5px 0px 0px;
                    }
                   
                    .HeaderBar .signOut {
                        opacity: 0;
                        background: #fff;
                        ${effects.shadow1}
                        position: absolute;
                        top:100%;
                        right:-1px;
                        width:120px;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        border:1px solid ${colors.mid};
                        border-radius: 5px 0px 5px 5px;
                        transform: scale(0);
                        transition: all 0.3s;
                        transform-origin: top right;
                    }
                    
                    .HeaderBar .logoutButton {
                        ${fonts.bold};
                        background: #11ABDD;
                        color: #fff;
                        font-size: 14px;
                        padding: 6px 22px;
                        border: none;
                        border-radius: 5px;
                        display: inline-block;
                        cursor: pointer;
                    }
                    .HeaderBar .logoutButton:hover {
                        opacity: 0.9;
                    }
                    @media print {
                        .HeaderBar .userArea, .HeaderBar .blue-bar {
                            display: none;
                        }
                       

                    }
                    @media screen and (max-width:767px) {
                        .HeaderBar .userArea, .HeaderBar .blue-bar {
                            display: none;
                        }
                        .HeaderBar .logoImg {
                            width: 134px;
                            height:45px;
                        }
                        .HeaderBar .small-menu {
                            display: block;
                        }
                        .HeaderBar .small-menu button {
                            background: none;
                            border:none;
                        }
                        .HeaderBar .top {
                            padding:5px 0px 5px 20px;
                        }
                    }
                `}</style>

                <style global="true" jsx="true">{`
                    .HeaderBar .button:hover .signOut{
                        opacity: 1;
                        transform: scale(1);
                    }
                `}</style>
            </div>
        )
    }
}

HeaderBar.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        authedUser : state.auth.authedUser,
        profile : state.profile.profile,
        enabledForCustomer : state.auth.enabledForCustomer,
        permissions : state.auth.permissions
    };
}

export default  connect(mapStateProps, { logoutUser, sidebarActive })(HeaderBar);
