import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fonts, colors } from '../../assets/css/theme';
import Status from './Status';
import withRouter from '../../util/withRouter';
import LineStatus from './LineStatus';

class OrderMetaStatus extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            deliveries : [],
            credits : [],
            outstanding : 0,
            open : true
        }

    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ 
        this.setInternals(this.props)
    }
    UNSAFE_componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){
        let total = props.line.Quantity;
        let deliveries = [];
        let credits = [];
        
        if( props.metaByKey[props.order.id] && this.props.line &&   props.metaByKey[props.order.id][ props.line.ItemCode]){
            let orderMeta = props.metaByKey[props.order.id][ props.line.ItemCode];
            if(orderMeta.deliveries){
                orderMeta.deliveries.forEach((del)=>{
                    total -= del.quantity;
                    deliveries.push({
                        date : moment(del.date).format('DD-MM-YYYY'),
                        quantity : del.quantity,
                        status : del.status,
                        id : del.deliveryId
                    })
                })
            }

            if(orderMeta.credits){
                orderMeta.credits.forEach((del)=>{
                    total -= del.quantity;
                    credits.push({
                        date : moment(del.date).format('DD-MM-YYYY'),
                        quantity : del.quantity,
                        status : del.status,
                        id : del.creditId
                    })
                })
            }
            
        }
        this.setState({
            outstanding : total, 
            deliveries : deliveries,
            credits : credits
        })


    }

    render(){

       
        const renderDeliveries = ()=>{
            if(!this.state.deliveries.length) return null;    
            return this.state.deliveries.map((d, idx)=>{
                return (
                    <div className="delivery" key={`${this.props.order.id}-${this.props.line.ItemCode}-${idx}`} onClick={()=>{this.props.router.navigate(`/deliveries/${d.id}`)}}>
                        <span>{`Qty: ${d.quantity} on ${d.date}`} </span>
                        <Status mode="order" >{d.status}</Status>
                        <style jsx='true'>{`
                       
                
                            .delivery{
                                width:100%;
                                margin-bottom:5px;
                                display: flex;
                                justify-content: space-between;
                                border-bottom:1px solid #fff;
                                cursor: pointer;

                            }
                            .delivery:hover {
                                border-bottom:1px solid ${colors.mid};
                            }
                        `}</style>
                    </div>
                )
            })       
        }

        const renderCredits = ()=>{
            if(!this.state.credits.length) return null;    
            return this.state.credits.map((c, idx)=>{
                return (
                    <div className="delivery" key={`${this.props.order.id}-${this.props.line.ItemCode}-${idx}`} onClick={()=>{this.props.router.navigate(`/credits/${c.id}`)}} >
                        <span>{`Qty: ${c.quantity} on ${c.date}`} </span>
                        <Status mode="order">{c.status}</Status>
                        <style jsx='true'>{`
                       
                
                            .delivery{
                                width:100%;
                                margin-bottom:5px;
                                display: flex;
                                justify-content: space-between;
                            }
                        `}</style>
                    </div>
                )
            })       
        }

        const renderTitle = ()=>{
            if(!this.state.deliveries.length) return null;
            return (
                <div>
                    <div className="toggle" >Deliveries</div>
                    <div className={(this.state.open)? 'deliveries open' : 'deliveries'}>
                        {renderDeliveries()}
                    </div>
                </div>
            )
        }

        const renderCreditsTitle = ()=>{
            if(!this.state.credits.length) return null;
            return (
                <div>
                    <div className="toggle credit" >Credits</div>
                    <div className={(this.state.open)? 'deliveries open' : 'deliveries'}>
                        {renderCredits()}
                    </div>
                </div>
            )
        }
        const renderStatuses = ()=>{
            if(!this.props.line.LineStatuses || !this.props.line.LineStatuses.length) return null;
            return (
                <div className="line-statuses">
                    {
                        this.props.line.LineStatuses.map((ls, idx)=>{
                            return <LineStatus line={ls} key={idx}></LineStatus>
                        
                        })
                    }

                </div>
            )
        }
        return (
            <div className="OrderMetaStatus">
                {renderStatuses()}

                <style global="true" jsx="true">{`
                    .OrderMetaStatus .toggle {
                        ${fonts.bold}
                        font-size:13px;
                        color:${colors.orange};
                        cursor: pointer;
                        padding:5px 0px;
                    }
                    .OrderMetaStatus .toggle.credit {
                        color:${colors.loBlue};
                    }
                    .OrderMetaStatus .deliveries {
                        max-height: 0px;
                        overflow: hidden;
                        transition:all 0.3s;
                    }
                  
                    .OrderMetaStatus .deliveries.open {
                        max-height: 500px;
                    }

                `}</style>


            </div>
        )
    }
}

OrderMetaStatus.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        metaByKey : state.customer.orderMeta
    };
}

export default  withRouter(connect(mapStateProps, {  })(OrderMetaStatus));
