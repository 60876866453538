import React from 'react';
import { fonts, colors } from '../../assets/css/theme';


class TableRow extends React.Component {   

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className={'TableRow '+this.props.className }>
                {this.props.children}
                <style jsx='true'>{`
                    .TableRow {
                        display: flex;
                        ${fonts.reg}
                        font-size:14px;
                        padding:10px 0px;
                        align-items: center;
                        border-bottom:1px solid ${colors.mid};
                        background: #fff;
                    }
                    @media screen and (max-width:767px){
                        .TableRow {
                            flex-wrap: wrap;
                            justify-content: space-between;
                        }
                    }
                        
                `}</style>
            </div>
        )
    }
}

TableRow.propTypes = {
    
}

export default TableRow;