import React from 'react';
import { connect } from 'react-redux';
import { fonts, colors, effects } from '../../assets/css/theme';
import { useNavigate } from 'react-router-dom';
import withRouter from '../../util/withRouter';

class Dashboard extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="Dashboard">
                <h1 className="title">Welcome to the Arcadia Medical Customer Portal </h1>

                <div className="buttons">
                    <div className="button" onClick={()=>{this.props.router.navigate('/catalogue')}}>
                        <img className="button-img"src={require('./../../assets/img/catalogue-icon.svg').default} alt=""/>
                        <div className="button-title">Catalogue</div>
                        <div className="button-description">View products and create new orders</div>
                    </div>
                    <div className="button" onClick={()=>{this.props.router.navigate('/orders')}}>
                        <img className="button-img"src={require('./../../assets/img/orders-icon.svg').default} alt=""/>
                        <div className="button-title">Orders</div>
                        <div className="button-description">View current and completed orders</div>
                    </div>
                    <div className="button"  onClick={()=>{this.props.router.navigate('/deliveries')}}>
                        <img className="button-img"src={require('./../../assets/img/deliveries-icon.svg').default} alt=""/>
                        <div className="button-title">Deliveries</div>
                        <div className="button-description">View active and completed deliveries</div>
                    </div>
                    <div className="button"  onClick={()=>{this.props.router.navigate('/invoices')}}>
                        <img className="button-img"src={require('./../../assets/img/invoices-icon.svg').default} alt=""/>
                        <div className="button-title">Invoices</div>
                        <div className="button-description">View invoices for past orders</div>
                    </div>

                    <div className="button"  onClick={()=>{this.props.router.navigate('/credits')}}>
                        <img className="button-img"src={require('./../../assets/img/credits-icon.svg').default} alt=""/>
                        <div className="button-title">Credits</div>
                        <div className="button-description">View credits against past orders</div>
                    </div>

                </div>
                <style jsx='true'>{`
                    .Dashboard .title {
                        ${fonts.bold};
                        font-size:22px;
                        color:${colors.dark};
                        margin:0px 0px 20px 0px;
                    }    
                    .Dashboard .intro {
                        ${fonts.reg};
                        color:${colors.dark};
                        margin:0px 0px 20px 0px;
                        max-width: 700px;
                    }
                    .Dashboard .buttons {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                    .Dashboard .button {
                        width:calc( 33% - 10px );
                        background: #fff;
                        padding:30px;
                        margin-bottom: 19px;
                        ${effects.shadow1}
                        transition: all 0.3s;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                    .Dashboard .button:hover {
                        transform: scale(1.05);
                        background: ${colors.hiBlue};
                    }
                    .Dashboard .button-img {
                        width:100px;
                        height: 100px;
                        display: block;
                        margin:0px auto;
                    }
                    .Dashboard .button-title {
                        ${fonts.bold}
                        color:${colors.midBlue};
                        font-size:20px;
                        text-align: center;
                        margin-bottom:10px;
                        
                    }
                    .Dashboard .button-description {
                        ${fonts.bold}
                        color:${colors.dark};
                        font-size:12px;
                        text-align: center;
                        max-width:130px;
                        margin:auto;
                    }
                    @media screen and (max-width:767px){
                        .Dashboard .buttons {
                            display: block;
                        }
                        .Dashboard .button {
                            width:100%;
                        }
                        .Dashboard .button-img {
                            width:30px;
                            height: 30px;
                            
                        }
                    }
                `}</style>
            </div>
        )
    }
}

Dashboard.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default withRouter(connect(mapStateProps, {  })(Dashboard));

