import _ from 'lodash';
import moment from 'moment';

const INITIAL_STATE = {
    profile : null,
    invoices : [],
    deliveries : [],
    credits : [],
    orders : [],
    priceList : {},
    orderMeta : {},
    items : [],
    itemsByKey : {},
    accessRequests : {},
    backOrders : [],
    ordersByKey : {},
    listeners : [],
    groupCustomers : []
};

const orderFilter = (o) => Math.abs(moment(o.updateId * 1000)
        .diff(moment.now(), 'months')) <= 2;

const mapToNetPrice = (items) => {
    items.forEach((o) => {
        o.Total = parseFloat(o.Lines.reduce((sum, line)=>{
            return (Math.round((sum * 100) + (line.LineTotal*100)) / 100).toFixed(2)
        }, 0));
    });

    return items;
}

const customerReducer = (state = INITIAL_STATE, action) => {
    let attrs = {};
    switch (action.type){
        case 'CUSTOMER_PRICE_LIST_UPDATE':
            return {...state, priceList : action.payload};
        case 'CUSTOMER_PROFILE_UPDATE':
            return {...state, profile : action.payload};
        case 'CUSTOMER_INVOICES_UPDATE':
            return {...state, invoices : mapToNetPrice(action.payload)};
        case 'CUSTOMER_DELIVERIES_UPDATE': 
            return {...state, deliveries : mapToNetPrice(action.payload)};
        case 'CUSTOMER_CREDITS_UPDATE':
            return {...state, credits : mapToNetPrice(action.payload)};
        case 'CUSTOMER_ORDERS_UPDATE':
            let orders = _.filter(action.payload, orderFilter);
            orders = mapToNetPrice(orders);
            orders = _.sortBy(orders, 'DocDate').reverse();

            return {...state, orders : orders };
        case 'CUSTOMER_ORDER_META_UPDATE':
            return {...state, orderMeta : action.payload || {}};
        case 'CUSTOMER_ORDERS_ORDER_META_UPDATE':
           // debugger;
                return {
                    ...state, 
                    backOrders : action.payload.backOrders, 
                    ordersByKey : action.payload.ordersByKey
                };
        case 'ITEMS_UPDATE':
            attrs.items = _.sortBy(action.payload, 'ItemName');
            attrs.itemsByKey = {}
            attrs.items.forEach((i)=> {
                attrs.itemsByKey[i.ItemCode] = i;
            })
            return {...state, ...attrs };
        case 'CUSTOMER_ACCESS_REQUESTS_UPDATE':
            attrs.accessRequests = {}
            action.payload.map((i)=>(
                attrs.accessRequests[i.ItemCode] = i
            ))
            return {...state, ...attrs };
        case 'USER_LOGGED_OUT':
            return {...INITIAL_STATE}
        case 'CUSTOMER_LISTENERS_UPDATED':
            return {...state, listeners : action.payload };
        case 'GROUP_CUSTOMERS_UPDATE':
            return {...state, groupCustomers : action.payload };
        default:
            return state;
    }
}

export default customerReducer;