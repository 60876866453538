import React from 'react';
import moment from 'moment';
import { colors } from '../../assets/css/theme';

const formatDate = (str)=>{
    if(!str) return '';
    try {
        return moment(str).format('DD-MM-YYYY');
    }
    catch(e){
        return '';
    }
}


class LineStatus extends React.Component {   

    constructor(props){
        super(props);
        this.renderLine = this.renderLine.bind(this);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    renderLine(){
        let { Quantity, Status, ShipDate  } = this.props.line;

        const getColour = ()=>{
            switch(Status){
                case "Shipped":
                case "Invoiced":
                    return colors.green;
                case "Back-Ordered":
                    return colors.orange;
                default:
                    return "#000"
            }
        }
        switch(Status){
            case 'Back-Ordered':
                return (
                    <div className="LineStatus-elem">
                        <span style={{color:getColour()}}>{Quantity} to follow </span>
                        <span>: expected despatch on {formatDate(ShipDate)}</span>
                        
                    </div>
                )
            default:
                return (
                    <div className="LineStatus-elem">
                        <span style={{color:getColour()}}>{Quantity} {Status} </span>
                        <span>: shipped on {formatDate(ShipDate)}</span>
                        
                    </div>
                )
        }
    }
    render(){
        return (
            <div className="LineStatus">
                {this.renderLine()}
                <style global="true" jsx="true">{`
                    .LineStatus {
                        display:flex;
                        margin-top:5px;
                    }    
                    .LineStatus .LineStatus-elem {
                        display: flex;
                        flex:1;
                    }
                    .LineStatus .LineStatus-elem span:first-child {
                        font-weight: bold;
                    }
                    .LineStatus .LineStatus-elem span:last-child {
                        flex:1;
                    }
                `}</style>
            </div>
        )

        
    }
}

LineStatus.propTypes = {
    
}

export default LineStatus;