import React from 'react';
import { colors, fonts } from '../../assets/css/theme';

class ProductFilter extends React.Component {   

    constructor(props){
        super(props);
        this.renderFilterIcon = this.renderFilterIcon.bind(this);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    renderFilterIcon(val){
        if(val){
            return (
                <svg className="svg" viewBox="0 0 24 24">
                    <path d="M19.86,4a11.25,11.25,0,1,0,.19.19ZM11.91,21.84a9.93,9.93,0,1,1,9.93-9.93A9.93,9.93,0,0,1,11.91,21.84Z" fill={colors.green} />
                    <path d="M17.36,8.39a.65.65,0,0,0-.93,0h0l-5.82,5.82L7.55,11.15a.66.66,0,0,0-.94.93l3.53,3.53a.66.66,0,0,0,.94,0h0l6.28-6.29a.63.63,0,0,0,.05-.91A.06.06,0,0,0,17.36,8.39Z" fill={colors.green}/>
                </svg>
            )
          
        }
        return (
            <svg className="svg" viewBox="0 0 24 24">
                <path data-name="Path 1" d="M19.86,4a11.25,11.25,0,1,0,.19.19ZM11.91,21.84a9.93,9.93,0,1,1,9.93-9.93A9.93,9.93,0,0,1,11.91,21.84Z" fill={colors.mid}/>
            </svg>
        )
    }
/**
 * 
 * 
 *  filter1={this.state.overLabelledFilter}
                    onClick1={()=>{this.setState({ overLabelledFilter : !this.state.overLabelledFilter})}}
                    filter2={this.state.medicalProductFilter}
                    onClick2={()=>{this.setState({ medicalProductFilter : !this.state.medicalProductFilter})}}
 */
    render(){
        
        return (
            <div className="ProductFilter">
               <div className="title">Show Product Types:</div>

             

                { Object.entries(this.props.filters).map(groupToFilter => {
                  return (
                        <div className="filter" key={groupToFilter} onClick={() => {
                            let newEnableStatus = groupToFilter[1].enabled ? false : true   
                            this.props.switchShowGroup(groupToFilter[0], newEnableStatus)
                        }}>
                            <span className="label">{groupToFilter[1].displayedHeaderName}</span>
                            {this.renderFilterIcon(groupToFilter[1].enabled)}
                        </div>
                    )
                })}

                <style jsx="true">{`
                    .ProductFilter {
                        display: flex;
                        align-items: center;
                    } 
                    .filter {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }  
                    .label {
                        
                    } 
                    .titleShowProductTypes {
                        ${fonts.bold}
                        color:${colors.midBlue};
                        margin-right:8px;
                    }
                    .ProductFilter .title {
                        ${fonts.bold}
                        color:${colors.midBlue};
                        margin-right:8px;
                    }
                    @media screen and (max-width:767px){
                        .ProductFilter {
                            flex-wrap: wrap;
                        }
                        .ProductFilter .title, .ProductFilter .filter {
                            width:100%;
                        }
                    }
                    
                `}</style>

                <style global="true" jsx="true">{`
                    .ProductFilter .svg{
                        width: 20px;
                        height: 20px;
                        margin-left:5px;
                        margin-right:30px;
                    }
                `}</style>
            </div>
        )
    }
}

ProductFilter.propTypes = {
    
}

export default ProductFilter;