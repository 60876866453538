import React from 'react';
import { fonts, colors } from '../../assets/css/theme';

class ColTitle extends React.Component {   

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="ColTitle" style={{...this.props.style}}>
                {this.props.children}
                <style jsx='true'>{`
                    .ColTitle {
                        ${fonts.bold}
                        color : ${colors.midBlue};
                        font-size:14px;
                        
                    }
                    @media print {
                        .ColTitle {
                            font-size:12px;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

ColTitle.propTypes = {
    
}

export default ColTitle;