import React from 'react';
import { connect } from 'react-redux';
import { effects, colors, fonts } from '../../assets/css/theme';
import SearchBox from '../common/SearchBox';
import Fuse from "fuse.js";
import PaginationControls from '../common/PaginationControls';
import TableRow from '../common/TableRow';
import TableCell from './../common/TableCell';
import TableHeader from '../common/TableHeader';
import moment from 'moment';
import ViewButton from '../common/ViewButton';
import PrintButton from '../common/PrintButton';
import Order from '../common/Order';
import Popover from '../common/Popover';
import Status from '../common/Status';
import _ from 'lodash';
import withRouter from '../../util/withRouter';

class Credits extends React.Component {

    constructor(props){
        super(props);
        this.updateFilter = this.updateFilter.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.searchFields = [
            "CustomerRef",
            "Total",
            "OrderPlacedByName",
            "SearchString"
        ]
        this.state = {
            filterText : '',
            paginationCount : 15,
            currentPage : 0,
            totalPages : 1,
            records : [],
            paginationPages : [],
            selectedRecord : null,
            printingEnable: false
        }
    }

    updateFilter(filterVal){
        
        let records = this.props.records.slice();

        records = records.map((r)=>{
            r.SearchString = '';
            r.Lines = r.Lines.map((l)=>{
                r.SearchString += this.props.itemsByKey[l.ItemCode]?.ItemName+" ";
                return l;
            })
            return r
        })

        if(filterVal){
            const fuse = new Fuse(records, {
                shouldSort: true,
                tokenize: false,
                threshold: 0.2,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: this.searchFields
              });
            records = fuse.search(filterVal);
        }

        let totalPages = Math.ceil(records.length/this.state.paginationCount);
        let paginationPages = [];
        for(let i=0; i < totalPages; i++){
            paginationPages.push(i);
        }

        this.setState({
            filterText : filterVal,
            records : records,
            totalPages : totalPages,
            paginationPages : paginationPages,
            currentPage : 0
        })

    }

    componentDidMount(){ 
        this.setInternals(this.props);

        //load record
       
        setTimeout(()=>{
            if(this.props.router.params.id){
                let records = this.props.records.slice().filter((r)=>{
                    return this.props.router.params.id === r.id
                });
                if(records[0]){
                    this.setState({selectedRecord : records[0]});
                }
            }
        }, 1000)


    }
    UNSAFE_componentWillReceiveProps(newProps){
        this.setInternals(newProps)
    }

    setInternals(props){
        let records = props.records.slice();
        let selectedRecord = null;
        records = records.map((r)=>{
            r.SearchString = '';
            r.Lines = r.Lines.map((l)=>{
                if(props.itemsByKey[l.ItemCode]){
                    r.SearchString += props.itemsByKey[l.ItemCode]?.ItemName+" ";
                }
                return l;
            })
            return r
        })

        if(this.state.filterText){
            const fuse = new Fuse(records, {
                shouldSort: true,
                tokenize: false,
                threshold: 0.2,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: this.searchFields
              });
            records = fuse.search(this.state.filterText);
        }

        if(this.props.router.params.id){
            let records = this.props.records.slice().filter((r)=>{
                return this.props.router.params.id === r.id
            });
            if(records[0]){
                selectedRecord = records[0]
                this.setState({selectedRecord : records[0]});
                // window.history.replaceState(null, '', "/Credits");
            }
        }
        
        records = _.sortBy(records, 'DocDate').reverse();

        this.setState({
            selectedRecord :  selectedRecord,
            records : records,
            totalPages : Math.ceil(records.length/this.state.paginationCount)
        });
        //handle loading delivery id

    }

  

    renderRows(){

        const formatDate = (str)=>{
            if(!str) return '';
            try {
                return moment(str).format('DD-MM-YYYY');
            }
            catch(e){
                return '';
            }
        }

        return this.state.records
            .filter((i, idx)=>{
                //pagination filter
                if(idx > (this.state.currentPage * this.state.paginationCount)+(this.state.paginationCount-1)) return false;
                if(idx < this.state.currentPage * this.state.paginationCount) return false;
                return true;
            })
            .map((r, idx)=>{
                // When filtering the orders by searching the items are
                // returned with a wrapping object and the actual data is
                // inside the "item" key. This is a quick fix and should
                // probably be fixed at the root but that may break other
                // parts of the site.
                r = typeof r.CustomerRef === 'undefined' ? r.item : r;
                
                return (r) ? (
                    <TableRow key={idx}>
                        <TableCell className="date">{formatDate(r.DocDate)}</TableCell>
                        <TableCell className="po">{r.CustomerRef}</TableCell>
                        <TableCell className="status">{<Status>{r.Status}</Status>}</TableCell>
                        <TableCell className="total">£{(r.TaxTotal + r.Total)?.toFixed(2)}</TableCell>
                        <TableCell className="control"><ViewButton onClick={()=>{this.setState({ selectedRecord : r})}} /></TableCell>
                        <TableCell className="control"><PrintButton onClick={()=>{this.setState({selectedRecord: r, printingEnable: true})}} /></TableCell>
                    </TableRow>
                ) : ( <TableRow key={idx}></TableRow>)
            })
    }

    render(){

        if(this.state.selectedRecord && this.state.printingEnable) {
            setTimeout(() => {
                window.print();
            }, 1000)

            this.setState({printingEnable : false});
        }

        const renderCurrentOrderTab = ()=>{
            return ''
        }
        
        return (
            <div className="Credits">
                <div className="tabs">
                    <div className="tab">Credits</div>
                    {renderCurrentOrderTab()}
                    <div className="search-area">
                        <SearchBox
                            placeholder="Search by PO, invoice amount or product"
                            value={this.state.filterText}
                            onChange={(val)=>{this.updateFilter(val)}}
                        />
                    </div>
                   
                </div>

               <div className="Credits-wrap">
                    
                    <div className="Credits-records">
                        <TableRow>
                            
                            <TableHeader className="date">Date</TableHeader>
                            <TableHeader className="po">Credit Note Number</TableHeader>
                            <TableHeader className="status">Status</TableHeader>
                            <TableHeader className="total">Total</TableHeader>
                            <TableHeader className="control"> </TableHeader>
                            <TableHeader className="control"> </TableHeader>

                        </TableRow>
                        {this.renderRows()}
                    </div>


                    <PaginationControls
                        style={{marginTop:20}}
                        totalPages={this.state.totalPages}
                        currentPage={this.state.currentPage}
                        onChange={(page)=>{this.setState({currentPage : page})}} 
                    />

                    <Popover 
                        isOpen={this.state.selectedRecord}
                        onClose={()=>{this.setState({selectedRecord : null})}}
                    >
                        <Order order={this.state.selectedRecord}  title="Credit" />
                    </Popover>

                </div>
                    <style jsx='true'>{`
                    .Credits-wrap {
                        padding:20px;
                        background: #fff;
                        ${effects.shadow1}
                        margin-top:0px;
                        min-height: 70vh;
                    }

                    .tabs {
                        display: flex;
                        position: relative;
                    }
                    .search-area {
                        flex:1;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }

                    .tab {
                        background: #fff;
                        color:${colors.midBlue};
                        width: 220px;
                        height: 45px;
                        padding:0px 10px 0px 20px;
                        display: flex;
                        align-items: center;
                        margin-right:5px;
                        margin-bottom:0px;
                        border-radius: 2px 30px 0px 0px;
                        ${effects.shadow1}
                        ${fonts.bold}
                        font-size:16px;
                        cursor: default;
                        z-index: 1;
                    } 
                       
                    .tab:after {
                        content : "";
                        position: absolute;
                        bottom:-10px;
                        left:0px;
                        right:0px;
                        display: block;
                        height: 10px;
                        background: #fff;
                        z-index: 1;
                    }
                `}</style>

                <style global="true" jsx="true">{`
                    .date{
                        text-align: left;
                        width:100px;
                    }
                    .track{
                        text-align: left;
                        width:200px;
                    }

                    
                    .po{
                        flex:1 1 0%;
                        text-align: left;
                        
                    }

                    .name{
                        width:180px;
                        text-align: center;
                    }

                    
                    .status{
                        width:100px;
                    }
                    .total{
                        width:100px;
                        text-align: right;
                    }

                    .control{
                        width:50px;
                        text-align: right;
                    }

                    @media screen and (max-width:767px){

                        .search-area {
                            display: none;
                        }
                        .date{
                            text-align: left;
                            width:100%;
                        }
                       
                        .po{
                            width: 100%;
                            text-align: left;
                            
                        }
                    }
                    .control{
                        width:50px;
                        text-align: right;
                    }

                    @media screen and (max-width:767px){
                        .date{
                            text-align: left;
                            width:100%;
                        }
                       
                        .po{
                            width: 100%;
                            text-align: left;
                            flex: 0;
                        }
                        
                        .status{
                            text-align: left;
                        }
                        .total{
                            text-align: right;
                        }

                        .control{
                            width:50px;
                            text-align: right;
                        }
                        
                        .name {
                            min-width: 100%;
                            text-align: left;
                            flex: 0;
                        }

                        .total {
                            font-size: 18px;
                            flex: 1 1 0%;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

Credits.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        records : state.customer.credits,
        itemsByKey : state.customer.itemsByKey
    };
}

export default  withRouter(connect(mapStateProps, {  })(Credits));
