import React from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authAction';
import { fonts, colors } from '../../assets/css/theme';


class LogOutLink extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);

    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="LogOutLink" >
                <div className="link" onClick={this.props.logoutUser}>Sign Out</div>
                <style jsx='true'>{`
                    .LogOutLink {
                        text-align: center;
                    }
                    .link {
                        display: inline-block;
                        border:1px solid ${colors.mid};
                        padding:5px 10px;
                        ${fonts.bold};
                        font-size:18px;
                        color:${colors.mid};
                        text-align: center;
                        margin-top:60px;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                    .link:hover {
                        background: #fff;
                    }    
                `}</style>
            </div>
        )
    }
}

LogOutLink.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, { logoutUser })(LogOutLink);
