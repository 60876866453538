import React from 'react';
import TableRow from '../common/TableRow';
import TableCell from './../common/TableCell';
import TableHeader from '../common/TableHeader';
import ViewButton from '../common/ViewButton';
import { effects } from '../../assets/css/theme';
import moment from 'moment';
import Status from '../common/Status';
import _ from 'lodash';

class BackordersList extends React.Component {   

    constructor(props){
        super(props);
        this.renderRows = this.renderRows.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            records : []
        }
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ 
        this.setInternals(this.props)
    }

    UNSAFE_componentWillReceiveProps(newProps){
        this.setInternals(newProps)
    }
    
    // backOrders= _.sortBy(backOrders, ['a', 'b'], ['asc', 'asc']);

    setInternals = (props)=>{

        const getItemName = (itemCode)=>{
            if(this.props.itemsByKey && this.props.itemsByKey[itemCode]){
                return this.props.itemsByKey[itemCode].ItemName
            }
            return itemCode;
        }
        let backOrders = props.records.map((r)=>{
            r.ItemName = getItemName(r.ItemCode); 
            return r;
        })
        backOrders= _.sortBy(backOrders, ['ItemName', 'ShipDate'], ['asc', 'asc']);

        this.setState({records: backOrders});
    }
    renderRows(){

        const formatDate = (str)=>{
            if(!str) return '';
            try {
                return moment(str).format('DD-MM-YYYY');
            }
            catch(e){
                return '';
            }
        }

        return this.state.records
            .map((r, idx)=>{
                return (
                    <TableRow key={idx}>
                        <TableCell className="ref">{r.CustomerRef}</TableCell>
                        <TableCell className="quantity">{r.Quantity}</TableCell>
                        <TableCell className="name">
                            <span className="itemcode">{r.ItemCode}</span>
                            {r.ItemName}
                        </TableCell>
                        
                        <TableCell className="status">{<Status mode="backorders">{r.Status}</Status>}</TableCell>
                        <TableCell className="date">{formatDate(r.ShipDate)}</TableCell>
                        <TableCell className="control"><ViewButton onClick={()=>{this.props.onOrderSelect(r.OrderId)}} /></TableCell>
                    </TableRow>
                )
            })
    }
    /**
     * 
     * ItemCode(pin): "PRD003SEP"
OrderDate(pin): "2019-03-21T00:00:00"
OrderId(pin): "15tBro5eJHntjmyk5G1z"
OrderPlacedByName(pin): "Emma Kettle-Davies"
CustomerRef(pin): "E2E Multi Test"
Quantity(pin): 50
ShipDate(pin): "2019-03-22T00:00:00"
Status(pin): "Back-Ordered"
     */
    
    render(){
        return (
            <div className="Backorders-wrap">
                    
                    
                    
                    <div className="Backorders-records">
                        <TableRow> 
                            <TableHeader className="ref">PO Number</TableHeader>
                            <TableHeader className="quantity">Qty</TableHeader>
                            <TableHeader className="name">Product Name</TableHeader>
                            <TableHeader className="status">Status</TableHeader>
                            <TableHeader className="date">Expected Dispatch Date</TableHeader>
                            <TableHeader className="control"> </TableHeader>
                        </TableRow>
                        {this.renderRows()}
                    </div>


                   


                    <style jsx>{`
                    .Backorders-wrap {
                        padding:20px;
                        background: #fff;
                        ${effects.shadow1}
                        margin-top:0px;
                        min-height: 70vh;
                    }
                  
                   
                    
                    

                    .Backorders-records :global(.itemcode){
                        text-align: left;
                        font-weight: 300;
                        display: block;
                        font-size:0.8em;
                    }

                    .Backorders-records :global(.quantity){
                        text-align: center;
                        width:50px;
                    }

                    .Backorders-records :global(.name){
                        flex:1;
                        text-align: left;
                    }

                    .Backorders-records :global(.date){
                        width:150px;
                    }

                    .Backorders-records :global(.ref){
                        width:150px;
                        text-align: left;
                        
                    }

                    
                    .Backorders-records :global(.status){
                        width:100px;
                        white-space: normal;
                        font-size:0.9em;
                    }

                    .Backorders-records :global(.control){
                        width:50px;
                        text-align: right;
                    }

                    

                    @media screen and (max-width:767px){
                       

                        .Backorders-records :global(.TableHeader){
                            // display: none;
                        }
                        .Backorders-records :global(.itemcode){
                            text-align: left;
                            font-weight: 300;
                            display: block;
                            font-size:0.8em;
                        }

                        .Backorders-records :global(.quantity){
                            text-align: left;
                            width:100%;
                        }

                        .Backorders-records :global(.quantity:before){
                            content : "Qty: "
                        }

                       
                        .Backorders-records :global(.date){
                            flex:1;
                            text-align: right;
                        }
                        .Backorders-records :global(.date:before){
                            //content : "Expected Dispatch Date: "
                        }


                        .Backorders-records :global(.ref){
                            width:100%;
                            text-align: left;
                            
                        }
                        .Backorders-records :global(.name){
                           
                            text-align: left;
                            flex:none;
                            width: 100%;
                            margin-bottom:5px;
                        }


                    
                        .Backorders-records :global(.status){
                            width:100px;
                            white-space: normal;
                            font-size:0.9em;
                            text-align: left;
                        }

                        .Backorders-records :global(.control){
                            width:50px;
                            text-align: right;
                        }
                    }

                `}</style>
                    

                </div>
        )
    }
}

BackordersList.propTypes = {
    
}

export default BackordersList;