import React from 'react';
import { connect } from 'react-redux';
import { addFavourite, removeFavourite } from '../../actions/customerAction';
import { colors } from '../../assets/css/theme';

class Favourite extends React.Component {

    constructor(props){
        super(props);
        this.toggleFavourite = this.toggleFavourite.bind(this);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    toggleFavourite(){
        if(!this.props.profile || !this.props.profile.favourites || this.props.profile.favourites.indexOf(this.props.itemCode) === -1){
            this.props.addFavourite(this.props.itemCode)
        } else {
            this.props.removeFavourite(this.props.itemCode);
        }
    }

    render(){

        const getFill = ()=>{
            return (!this.props.profile || !this.props.profile.favourites || this.props.profile.favourites.indexOf(this.props.itemCode) === -1) ? '#fff' : colors.orange;
        }


        return (
            <div className="Favourite">
                <svg className="svg" viewBox="0 0 24 24" onClick={this.toggleFavourite}>
                    <path d="M22.52,11a1.58,1.58,0,0,0-.87-2.69l-5.33-.77a.69.69,0,0,1-.52-.38L13.41,2.34a1.58,1.58,0,0,0-2.11-.72,1.67,1.67,0,0,0-.72.72L8.2,7.17a.7.7,0,0,1-.52.38l-5.33.78A1.56,1.56,0,0,0,1,10.11a1.54,1.54,0,0,0,.46.9l3.85,3.76a.71.71,0,0,1,.2.62l-.91,5.3a1.58,1.58,0,0,0,2.29,1.67l4.77-2.51a.74.74,0,0,1,.65,0l4.76,2.51a1.57,1.57,0,0,0,2.29-1.67l-.91-5.3a.71.71,0,0,1,.2-.62Z" fill={getFill()} />
                    <path id="star" d="M22.52,11a1.58,1.58,0,0,0-.87-2.69l-5.33-.77a.69.69,0,0,1-.52-.38L13.41,2.34a1.58,1.58,0,0,0-2.11-.72,1.67,1.67,0,0,0-.72.72L8.2,7.17a.7.7,0,0,1-.52.38l-5.33.78A1.56,1.56,0,0,0,1,10.11a1.54,1.54,0,0,0,.46.9l3.85,3.76a.71.71,0,0,1,.2.62l-.91,5.3a1.58,1.58,0,0,0,2.29,1.67l4.77-2.51a.74.74,0,0,1,.65,0l4.76,2.51a1.57,1.57,0,0,0,2.29-1.67l-.91-5.3a.71.71,0,0,1,.2-.62ZM17.6,15.54l.91,5.3a.71.71,0,0,1-.57.81.74.74,0,0,1-.44-.07l-4.77-2.51a1.59,1.59,0,0,0-1.47,0L6.49,21.58a.7.7,0,0,1-.94-.3.74.74,0,0,1-.07-.44l.91-5.3a1.58,1.58,0,0,0-.45-1.4L2.09,10.39a.7.7,0,0,1,0-1,.64.64,0,0,1,.39-.2l5.34-.78A1.59,1.59,0,0,0,9,7.56l2.37-4.83a.71.71,0,0,1,.94-.31.77.77,0,0,1,.31.31L15,7.56a1.59,1.59,0,0,0,1.19.86l5.33.78a.7.7,0,0,1,.59.79.77.77,0,0,1-.2.39l-3.86,3.76A1.58,1.58,0,0,0,17.6,15.54Z" fill="#cecece"/>
                </svg>

                <style jsx='true'>{`
                    .Favourite {
                        position: absolute;
                        top:0px;
                        left:0px;
                        cursor: pointer;
                    }   
                    .svg {
                        width:25px;
                        height: 25px;
                    } 
                    @media screen and (max-width:767px){
                        .Favourite {
                            display: none;
                        }

                    }
                `}</style>
            </div>
        )

       
        
    }
}

Favourite.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        profile : state.profile.profile
    };
}

export default  connect(mapStateProps, { addFavourite, removeFavourite })(Favourite);
