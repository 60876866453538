import React from 'react';
import { connect } from 'react-redux';
import { colors, fonts } from '../../assets/css/theme';
import { requestProductAccess } from '../../actions/customerAction';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ScaleLoader } from 'react-spinners';


const MySwal = withReactContent(Swal);

class RequestProductAccessButton extends React.Component {

    constructor(props){
        super(props);
        this.requestAccess = this.requestAccess.bind(this);
        this.state = {
            working : false
        }

    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    async requestAccess(){
        if(!this.props.product) return;
        if(this.state.working) return false;
        try {
            this.setState({working : true});
            await this.props.requestProductAccess(this.props.product);
            

            let response = await MySwal.fire({
                title: 'Thankyou',
                text: `We’ve sent you an over label approval form to ${this.props.user.email}. Please follow the instructions in the email so we can enable you to purchase this product through us.`,
                type: 'success',
                showCancelButton: false,
                confirmButtonText: 'OK',               
              })
              if(response.value){
            
            }
              
        }
        catch(e){
            /*let response =*/ await MySwal.fire({
                title: 'There was a problem',
                text: e,
                type: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',               
              })
              this.setState({working : false});
        }
    }
    render(){
        if(this.props.request){
            return (
                <div className="RequestProductAccessButton">
                    <button className="request" >REQUESTED {(this.props.request.requestedBy)? 'by '+this.props.request.requestedBy : ''}</button>
                    <style jsx='true'>{`
                        .request {
                            background: ${colors.green};
                            ${fonts.bold}
                            color:#fff;
                            font-size:10px;
                            text-transform: uppercase;
                            display: block;
                            padding:7px 20px;
                            border:none;
                            border-radius: 15px;
                            cursor: default;
                        }
                            @media screen and (max-width:767px){
                            .RequestProductAccessButton {
                                width: 100%;
                                margin-top:10px;
                            }
                        }
                        
                    `}</style>
                </div>
            )
        }
        return (
            <div className="RequestProductAccessButton">
                <button className="request" onClick={this.requestAccess}>
                {(!this.state.working)?'REQUEST ACCESS' : ''}
                <ScaleLoader
                    style={{position:'relative', top:5}}
                    sizeUnit={"px"}
                    size={10}
                    height={10}
                    color={'#fff'}
                    loading={this.state.working}
                    />
                </button>
                <style jsx='true'>{`
                    .request {
                        background: ${colors.midBlue};
                        ${fonts.bold}
                        color:#fff;
                        font-size:10px;
                        text-transform: uppercase;
                        display: block;
                        padding:7px 20px;
                        border:none;
                        border-radius: 15px;
                        cursor: pointer;
                    }
                    .request:hover {
                        opacity: 0.9;
                    }
                    @media screen and (max-width:767px){
                        .RequestProductAccessButton {
                            width: 100%;
                            margin-top:10px;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

RequestProductAccessButton.propTypes = {
    
}

const mapStateProps = (state, props) => {
    return {
        request : state.customer.accessRequests[props.product.ItemCode],
        user : state.auth.authedUser
    };
}

export default  connect(mapStateProps, { requestProductAccess })(RequestProductAccessButton);
