import React from 'react';
import { connect } from 'react-redux';
import logoSrc from './../../assets/img/logo-white.svg';
import firebase from 'firebase/app';
import isEmail from 'validator/lib/isEmail';
import { colors, fonts } from '../../assets/css/theme';
import { loginWithEmail } from '../../actions/authAction';

class SignIn extends React.Component {

    constructor(props){
        super(props);
        this.updateEmail = this.updateEmail.bind(this);
        this.signInUser = this.signInUser.bind(this);
        this.enterSignIn = this.enterSignIn.bind(this);
        this.state = {
            signInSent : false,
            email : '',
            valid : false,
            error : '',
            working : false
        }
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){
        let that = this;
        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            //load email
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            firebase.auth().signInWithEmailLink(email, window.location.href)
            .then(function(result) {
                window.localStorage.removeItem('emailForSignIn');
                window.history.pushState({}, "standard", "/");
            })
            .catch(function(error) {
                console.error(error);
                that.setState({error : error.message})
            });
        }
    }

    updateEmail(str){
        this.setState({
            email : str.target.value,
            valid : isEmail(str.target.value)
        })
    }
    
    enterSignIn(e){
        if (e.key === 'Enter' && this.state.valid) {
            this.signInUser();
        }
    }
    async signInUser(){
        this.setState({ working : true, error : '', signInSent : false })
        try {
            await this.props.loginWithEmail(this.state.email);
            this.setState({ working : false, error : '', signInSent : true })

        }
        catch(e){
            this.setState({ working : false, error : e.message, signInSent : false })
        }
    }
    

    render(){
        if(this.state.signInSent){
            return (
                <div className="SignIn">
                    <img className="logo" src={logoSrc} alt=""/>
                    <p className="message">An email has been sent to  {this.state.email} with a special link to log you in.</p>
                    <style jsx='true'>{`
                        .message{
                            ${fonts.reg}
                            text-align: center;
                            color:#fff;
                            max-width: 450px;
                            margin:auto;
                        }

                        .logo {
                            width:312px;
                            height:106px;
                            display: block;
                            margin:auto;
                            margin-bottom:20px;
                        }
                `}</style>
                </div>
            )
            
        }

        return (
            <div className="SignIn">
                <img className="logo" src={logoSrc} alt=""/>
                <p className="title">Customer Order Portal</p>
                <p className="message">Please login using your <b>offical work email address</b>.</p>
                <div className="form">
                    <input 
                        type="text" 
                        className="email"
                        onChange={this.updateEmail}
                        value={this.state.email}
                        onKeyPress={this.enterSignIn}
                    />
                    <button
                        
                        onClick={this.signInUser}
                        className="button"
                        disabled={!this.state.valid}
                    >Log In</button>
                </div>


                {(this.state.error)? <p className="error">{this.state.error}</p> : null}
                
                <style jsx='true'>{`
                    .SignIn {
                        max-width: 650px;
                        margin:10px;
                    }
                    
                    .SignIn .error {
                        ${fonts.bold}
                        text-align: center;
                        color:${colors.orange};
                        margin-top:10px;
                    }

                    .SignIn .logo {
                        width:312px;
                        height:106px;
                        display: block;
                        margin:auto;
                        margin-bottom:20px;
                    }

                    .SignIn .form {
                        display: flex;
                        max-width: 360px;
                        margin:auto;
                    } 
                    .SignIn .email {
                        ${fonts.reg}
                        flex:1;
                        height: 50px;
                        color: ${colors.dark};
                        padding:0px 14px;
                        font-size:14px;
                        border:none;
                        border-radius: 5px 0px 0px 5px;
                    }
                    .SignIn .button {
                        background: ${colors.midBlue};
                        color:#fff;
                        ${fonts.bold}
                        font-size:14px;
                        padding:0px 14px;
                        border:none;
                        border-radius: 0px 5px 5px 0px;
                    }
                    .SignIn .button:disabled {
                        background: ${colors.mid};
                    }

                    .SignIn p {
                        color:#fff;
                    }
                    .SignIn p.error {
                        font-weight: 700;
                        color:${colors.error};
                        margin-top:10px;
                    }
                    .SignIn h1 {
                        color : ${colors.primary}
                    }
                    
                `}</style>
                <style global="true" jsx="true">{`
                    .SignIn .title {
                        ${fonts.reg}
                        color: #fff;
                        text-align: center;
                        font-size: 35px;
                        margin-bottom: 80px;
                    }

                    .SignIn .message{
                        ${fonts.reg}
                        text-align: center;
                        color:#fff;
                    }

                    .SignIn .message b{
                        ${fonts.bold}
                    }
                `}</style>

                {/* <style global jsx='true'>{`
                    .SignIn {
                        max-width: 370px;
                        margin:10px;
                    }

                    .SignIn 
                    p {
                        color:#fff;
                    }
                    p.error {
                        font-weight: 700;
                        color:${colors.error};
                        margin-top:10px;
                    }
                    h1 {
                        color : ${colors.primary}
                    }
                    .logo {
                        width:200px;
                        height:39px;
                        display: block;
                        margin-bottom:20px;
                    }
                `}</style> */}
            </div>
        )

        
    }
}

SignIn.propTypes = {
    

}

const mapStateProps = (state) => {
    return {
        authedUser : state.auth.authedUser
    };
}

export default connect(mapStateProps, { loginWithEmail })(SignIn);
