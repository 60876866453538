import React from 'react';

class CentralFrame extends React.Component {   

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div style={{display:'flex', flexDirection : "column", ...this.props.style}}>
                <div className="CentralFrame">
                    {this.props.children}
                </div>
            <style jsx='true'>{`
                    .CentralFrame {
                        min-height: 100vh;
                        width:100%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }    
                    
                `}</style>
            </div>
        )
    }
}

CentralFrame.propTypes = {
    
}

export default CentralFrame;