import React from 'react';
import { colors } from '../../assets/css/theme';
import eyeSrc from './../../assets/img/ink.svg';
class PrintButton extends React.Component {   

    constructor(props){
        super(props);
        this.size = 28;
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){

        
        return (
            <div className="Button PrintButton" onClick={this.props.onClick}>
                <img className="Button-img" src={eyeSrc} alt=""/>
                <style global="false" jsx='true'>{`   
                    .Button.PrintButton {
                        background: ${colors.midBlue};
                        color:#fff;
                        width:${this.size}px;
                        height: ${this.size}px;
                        border:none;
                        border-radius: 5px;
                        display: inline-block;
                        transition: all 0.3s;
                        cursor: pointer;
                    }
                    PrintButton .Button-img {
                        width:${this.size}px;
                        height: ${this.size}px;
                    }
                    .PrintButton .Button:hover {
                        opacity: 0.9;
                    }
                    .PrintButton .Button:disabled {
                        background: ${colors.mid};
                    }

                `}</style>
            </div>
        )
    }
}

PrintButton.propTypes = {
    
}

export default PrintButton;