const INITIAL_STATE = {
    connected : false,
    connecting : true,
    sidebar : false
};

export default (state = INITIAL_STATE, action) => {
    let attrs;
    switch (action.type){
        case 'FIREBASE_INITIALISING':
            return { ...state, connected : false, connecting : true }
        case 'FIREBASE_CONNECTED':
            return { ...state, connected : true, connecting : false }
        case 'SET_SIDEBAR_STATE':
            return { ...state, sidebar : action.payload }
        // case 'USER_LOGGED_OUT':
        //     return {...INITIAL_STATE}
        default:
            return state;
    }
}