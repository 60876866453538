import React from 'react';
import { colors, fonts } from '../../assets/css/theme';

class ShowFavouritesButton extends React.Component {   

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        const getFill = ()=>{
            return (this.props.active) ? "#fff" : colors.orange;
        }
        const getButtonFill = ()=>{
            return (!this.props.active) ? "#fff" : colors.orange;
        }
        const getButtonEdge = ()=>{
            return (this.props.active) ? colors.mid : colors.orange;
        }

        return (
            <div className="ShowFavouritesButton"
                style={{
                    background : getButtonFill(),
                    color : getFill(),
                    borderColor : getButtonEdge()
                
                }}
                onClick={this.props.onClick}
            >
                <svg className="svg" viewBox="0 0 24 24" onClick={this.toggleFavourite}>
                    <path d="M22.52,11a1.58,1.58,0,0,0-.87-2.69l-5.33-.77a.69.69,0,0,1-.52-.38L13.41,2.34a1.58,1.58,0,0,0-2.11-.72,1.67,1.67,0,0,0-.72.72L8.2,7.17a.7.7,0,0,1-.52.38l-5.33.78A1.56,1.56,0,0,0,1,10.11a1.54,1.54,0,0,0,.46.9l3.85,3.76a.71.71,0,0,1,.2.62l-.91,5.3a1.58,1.58,0,0,0,2.29,1.67l4.77-2.51a.74.74,0,0,1,.65,0l4.76,2.51a1.57,1.57,0,0,0,2.29-1.67l-.91-5.3a.71.71,0,0,1,.2-.62Z" fill={getFill()} />
                </svg>

                <span className="text">
                {(this.props.active) ? "Show all products" : "Only show favourites"}
                </span>
                <style jsx='true'>{`
                    .ShowFavouritesButton {
                        width:170px;
                        height:30px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding:5px 10px;
                        border:1px solid ${colors.midHi};
                        cursor: pointer;
                        ${fonts.bold}
                        font-size:12px;
                    }
                    .ShowFavouritesButton .svg {
                        width:20px;
                        height:20px;
                        margin-right:0px;
                    }
                    .ShowFavouritesButton .text {
                        font-size:12px;
                        font-weight: bold;
                        flex:1;
                        text-align: center;
                        color: ${getFill()}
                    }

                    .ProductFilter .title {
                        padding: 0px;
                    }

                    @media screen and (max-width:767px){
                        .ShowFavouritesButton {
                            margin-top:10px;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

ShowFavouritesButton.propTypes = {
    
}

export default ShowFavouritesButton;